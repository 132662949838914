import Banner from 'sbf-design-system-component/banners/banner';
import Debounce from 'modules/debounce';

const $spinner = $('<span class="spinner"></span>');
const $emptyResults = $('.empty-filter-message');

function defaultUpdateFunction ($button, templateFunc, data) {
  $button.parent().find('ol').first().append(templateFunc(data.entities));
}

function loadMore ($button, $searchInput, url, queryDataFunc, entryTemplateFunc, loadedCountFunc, updateFunc) {
  const updateFunc2 = updateFunc || defaultUpdateFunction;

  $button.on('click', function () {
    $button.prop('disabled', true);
    $button.addClass('loading');
    $button.before($spinner);

    $.getJSON(url, queryDataFunc($searchInput.val()))
      .then(function (data) {
        updateFunc2($button, entryTemplateFunc, data);
      })
      .fail(function () {
        Banner.showError('Error loading more.', false, 4000);
      })
      .always(function () {
        $button.removeClass('loading');
        $button.prop('disabled', false);
        $spinner.remove();

        if (loadedCountFunc() >= $button.data('total')) {
          $button.prop('disabled', true);
          $button.hide();
        }
      });
  });
}

function searchField ($searchInput, $loadMoreButton, $targetContainer, url, queryDataFunc, entryTemplateFunc) {
  function searchAction () {
    const val = $searchInput.val();

    $emptyResults.addClass('hidden');
    $targetContainer.before($spinner);

    $.getJSON(url, queryDataFunc(val))
      .then(function (data) {
        $targetContainer.empty();
        $targetContainer.append(entryTemplateFunc(data.entities));

        if ($loadMoreButton && $loadMoreButton.length) {
          $loadMoreButton.data('total', data.total);
          if (data.total <= 50) { $loadMoreButton.hide(); } else { $loadMoreButton.prop('disabled', false).show(); }
        }

        if (!data.entities || data.entities.length === 0) {
          $emptyResults.removeClass('hidden');
        }
      })
      .fail(function () {
        Banner.showError('Error searching.', false, 4000);
      })
      .always(function () {
        $spinner.remove();
      });
  }

  const debouncedAction = Debounce(searchAction, 1000);

  $searchInput.on('keyup', function (e) {
    if (e.which === 13) { debouncedAction(); } else if (e.which === 9) { debouncedAction(); } else { debouncedAction(); }
  });
  $searchInput.parents('form').on('submit', function (e) {
    debouncedAction();
    return false;
  });
}

export default {
  loadMore,
  searchField
};
