import Mustache from 'lib/mustache';
import Pagination from 'mypage/pagination';

const $loadMoreButton = $('button#load_more');
const template = $('#body_template').html() || '';
const participantTemplate = $('#participant_row_template').html() || '';
const fundraiserTemplate = $('#fundraiser_row_template').html() || '';
const $donorSearchInput = $('#mypage_list_filter_input');
const $listBody = $('#listBody');
const $listBodyRow = $('#listBody li');

function generateNewEntries (Mustache, template) {
  return function (data) {
    let html = '';
    if (!data.length) {
      $('.empty-filter-message').removeClass('hidden');
    } else {
      html = data.reduce(function (a, b) {
        let tpl = template;
        if (b.type === 'f') { tpl = fundraiserTemplate; }
        if (b.type === 'p') { tpl = participantTemplate; }
        return a + Mustache.render(tpl, b);
      }, '');
    }
    return html;
  };
}

function loadedCount (selector) {
  return function () {
    const rows = $(selector).find('> li');
    return rows.length;
  };
}

let lastQuery = '';
function searchQuery (rowsSelector) {
  return function (data = '') {
    const $rows = $(rowsSelector);
    const previousQuery = lastQuery;
    lastQuery = data;

    // New query
    if (data !== previousQuery && data === '') { return {}; }
    if ($rows.length <= 0) { return { name_query: data }; }
    if (data !== previousQuery) { return { name_query: data }; }

    // Continue last query
    return {
      name_query: data
    };
  };
}

let entryGeneratorFunc;

if (template || participantTemplate || fundraiserTemplate) {
  entryGeneratorFunc = generateNewEntries(Mustache, template);
}

let url = window.location.pathname.split('/').pop();
url = `${window.ajaxBaseData.url_base}/${window.ajaxBaseData.id}/${window.ajaxBaseData.year}/${url}.json`;

if ($loadMoreButton.length && (template || participantTemplate || fundraiserTemplate)) {
  const moreDataQueryFunc = searchQuery($listBodyRow);
  const loadedCountFunc = loadedCount($listBody);

  Pagination.loadMore($loadMoreButton, $donorSearchInput, url, moreDataQueryFunc, entryGeneratorFunc, loadedCountFunc);
}

if ($donorSearchInput.length && $listBody.length) {
  const queryFunc = searchQuery($listBodyRow);

  Pagination.searchField($donorSearchInput, $loadMoreButton, $listBody, url, queryFunc, entryGeneratorFunc);
}
