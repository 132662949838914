import debounce from 'modules/debounce';

const Gallery = {
  containerID: '#photo-gallery',

  setup: function () {
    const self = this;
    self.$container = $(self.containerID);

    const debounced = debounce(function () {
      self.transformGallery();
      self.$container.removeClass('mypage__gallery--fade-out');
    }, 500);

    // run on resize
    $(window).resize(function () {
      debounced();
      self.$container.addClass('mypage__gallery--fade-out');
    });

    // run on layout change
    $('body').on('gallery.transformLayout', function () {
      self.transformGallery();
    });

    // run on page load
    self.transformGallery();
  },

  transformGallery: function () {
    // config
    const { $container } = this;
    const $boxes = this.$container.find('li');
    const $page = $('[role="main"]');
    const boxBorderLeft = parseInt($boxes.css('borderLeftWidth'));
    const boxBorderRight = parseInt($boxes.css('borderRightWidth'));
    const boxBorder = boxBorderLeft + boxBorderRight;
    const boxMarginLeft = parseInt($boxes.css('marginLeft'));
    const boxMarginRight = parseInt($boxes.css('marginRight'));
    const boxMargin = boxMarginLeft + boxMarginRight;
    const boxPaddingLeft = parseInt($boxes.css('paddingLeft'));
    const boxPaddingRight = parseInt($boxes.css('paddingRight'));
    const boxPadding = boxPaddingLeft + boxPaddingRight;
    const boxCount = $boxes.length; // number of boxes or images being shown on the page
    let itemLimit = 6;
    let maxItemWidth = 320;
    let galleryClass;
    let containerWidth = $container.width(); // width of parent container is used for calculating the size of the child elements

    // check number of items and set widthOverride and alignment class accordingly
    if (!$page.hasClass('mypage--gallery')) {
      if (boxCount <= 2 || boxCount === 13) {
        galleryClass = 'mypage__gallery--centered';
      } else if (boxCount === 3) {
        galleryClass = 'mypage__gallery--space-between';
      } else if (boxCount >= 4) {
        galleryClass = 'mypage__gallery--flex-start';
      }
    }

    // set a row item limit based on the container width
    if (containerWidth < 1032) {
      itemLimit = 4;
    }
    if (containerWidth <= 760) {
      itemLimit = 3;
    }
    if (containerWidth <= 480) {
      itemLimit = 2;
    }

    containerWidth--; // subtract 1 to prevent rounding issues from pushing us too wide

    // if internal gallery page
    if ($page.hasClass('mypage--gallery')) {
      maxItemWidth = 160;
    }

    const itemCount = (boxCount >= itemLimit) ? itemLimit : boxCount;

    // do math to get new box width
    const calcOffset = boxMargin + boxPadding + boxBorder;
    const simpleWidth = containerWidth / itemCount;
    let calcWidth = (simpleWidth - calcOffset);

    // set a max-width
    calcWidth = (calcWidth >= maxItemWidth) ? maxItemWidth : calcWidth;
    // set calculated width to a variable
    const newBoxWidth = calcWidth;
    // remove all old classes and add new applicable class
    $container.removeClass('mypage__gallery--enhanced mypage__gallery--space-between mypage__gallery--centered').addClass(galleryClass);

    // update each box with the new width and height. use the same value because we want them to be square.
    $boxes.each(function () {
      $(this).width(`${newBoxWidth}px`);
      $(this).height(`${newBoxWidth}px`);
    });
  }
};

export default Gallery;
