'use strict';

const $interiorNav = $('.fancy-buttons');
const LOG_TO_CONSOLE = false;
let fixedNavScrollOffset;
let $scrollTarget;
let isPinned = $interiorNav && $interiorNav.hasClass('is-pinned');

function log () {
  if (!!window.console && LOG_TO_CONSOLE) {
    console.log.apply(console, [].slice.call(arguments));
  }
}

function initInteriorNav () {
  log('init_interior_nav');
  if (isPinned) {
    // unpin temporarily for calculations
    $interiorNav.addClass('no-animate');
    $interiorNav.removeClass('is-pinned');
  }
  // prevent things from jumping around by making sure the container element is always the same height
  $interiorNav.parent().css('min-height', '');
  $interiorNav.parent().css('min-height', $interiorNav.parent().height());

  // store the offset for now
  if ($('#event_mypage_main').hasClass('venue-virtual')) {
    $scrollTarget = $('.fundraising__buttons');
  } else {
    $scrollTarget = $interiorNav;
  }
  fixedNavScrollOffset = $scrollTarget.offset().top;

  log(`fixed_nav_scroll_offset set to ${fixedNavScrollOffset}`);

  // return to previous state
  if (isPinned) {
    $interiorNav.addClass('is-pinned');
  }
}

function show () {
  if (!isPinned) {
    log('pinning now');
    $interiorNav.removeClass('no-animate');
    $interiorNav.addClass('is-pinned');
    isPinned = true;
  }
}

function hide () {
  if (isPinned) {
    log('unpinning');
    $interiorNav.removeClass('is-pinned');
    isPinned = false;
  }
}

function checkInteriorNavPosition () {
  if (!fixedNavScrollOffset) {
    initInteriorNav();
  }

  const scrollY = window.scrollY || window.pageYOffset || 0;

  if (scrollY > fixedNavScrollOffset) {
    show();
  } else {
    hide();
  }
}

let debounce;
$(window).on('load scroll resize', function () {
  clearTimeout(debounce);
  debounce = setTimeout(checkInteriorNavPosition, 50);
});

checkInteriorNavPosition();
