import ActionCable from 'actioncable';
import Mustache from 'lib/mustache';

let numTopTeams = 3;
let numTopParticipants = 3;
let numRecentDonors = 5;

export default function initMypageWebsockets (mypageType) {
  if ($('#event_mypage_main').hasClass('venue-virtual')) {
    numTopTeams = 8;
    numTopParticipants = 8;
    numRecentDonors = 8;
  }

  const participantTemplate = $('#top_participant_row_template').html();
  const teamTemplate = $('#top_team_row_template').html();
  const donorTemplate = $('#recent_donor_row_template').html();

  const mypage = $(`#${mypageType}_mypage_main`);
  const eventYear = mypage.data(`${mypageType}-year`);
  const mypageEntityId = mypage.data(`${mypageType}-id`);
  const websocketEnabled = mypage.data('websocket-donations');

  console.log(numTopParticipants);
  console.log(numTopTeams);
  console.log(numRecentDonors);

  if (websocketEnabled) {
    const consumer = ActionCable.createConsumer('/cable');
    consumer.subscriptions.create({ channel: 'DonationChannel', type: mypageType, id: mypageEntityId, year: eventYear },
      {
        received: function (data) {
          const payload = JSON.parse(data.body);
          const total = payload[`${mypageType}_total`];
          if (total) {
            updateMilestoneStretchBar(total);
            updateFundraisingBar(total);
          }
          updateTopTeams(payload);
          updateTopParticipants(payload);
          updateRecentDonors(payload);
        }
      }
    );
  }

  applyPositionClasses($('.fundraising__stats.participants ol'), numTopParticipants);
  applyPositionClasses($('.fundraising__stats.teams ol'), numTopTeams);

  function sortUsingNestedText (parent, childSelector, keySelector, maxItems) {
    parent.each(function () {
      const items = $(this).children(childSelector).sort(function (b, a) {
        const vA = +$(keySelector, a).text().replace(/[^0-9.]/g, '');
        const vB = +$(keySelector, b).text().replace(/[^0-9.]/g, '');
        return (vA < vB) ? -1 : (vA > vB) ? 1 : 0;
      });
      // give them data-position to apply absolute positioning via css
      items.each(function (i) {
        $(this).removeClass('fade-out').attr('data-position', (i + 1));
        if (i === maxItems) {
          $(this).addClass('fade-out');
        }
        if (i > maxItems) {
          $(this).remove();
        }
      });
    });
  }

  function applyPositionClasses (list, maxItems) {
    sortUsingNestedText(list, 'li', '.totals__amount', maxItems);
  }

  function updateFundraisingBar (total) {
    const formattedTotal = formatNumber(total, 0);

    $('.js-total-donations').text(formattedTotal);
    $('.fundraiser_progress').attr('value', +total);
  }

  /**
   * Update milestone and stretch goal amount and progress bars.
   *
   * @param     number    currentDonationAmount     The current donation amount.
   * @return    none
   */
  function updateMilestoneStretchBar (currentDonationAmount) {
    // Element references.
    const $parentContainer = $('.milestone__progress-bar'); // Milestone and stretch goal parent container.
    const $progressBarContainer = $parentContainer.find('.progress__bar-container'); // Container holding progress bars.
    const $progressBarBackground = $progressBarContainer.find('div:nth-child(1)'); // Full width background progress bar.
    const $progressBarStretchGoal = $progressBarContainer.find('div:nth-child(2)'); // Brown colored stretch goal progress bar.
    const $progressBarNormal = $progressBarContainer.find('div:nth-child(3)'); // Green colored progress bar.
    const $goalAmount = $('#goal_amount'); // Element containing goal amount.
    const $currentGoalText = $('.js-current-goal'); // Element containing current donation amount display value.
    const $milestoneListContainer = $parentContainer.find('> ul'); // UL containing list of milestones.
    // Element values.
    const hasStretchGoal = $progressBarContainer.find('div.stretch').length > 0;
    const goalAmount = parseFloat($goalAmount.text().replace(/[^0-9.]/g, ''));
    // Update current donation amount display value.
    if ($currentGoalText.length) {
      $currentGoalText.text(formatNumber(currentDonationAmount, 0, false)).attr('data-raised-amount', currentDonationAmount);
    }
    // Update progress bars and icons.
    if ($progressBarBackground.length && $progressBarStretchGoal.length && $progressBarNormal.length && $goalAmount.length) {
      // Calculate and set width of normal progress bar.
      $progressBarNormal.css(
        'width',
        `${currentDonationAmount <= goalAmount && hasStretchGoal ? currentDonationAmount / goalAmount * 86 : currentDonationAmount >= goalAmount ? 99 : currentDonationAmount / goalAmount * 99}%`
      );
      // Check each incomplete milestone amount against currentDonationAmount.
      $milestoneListContainer.find('> li.milestone.incomplete, > li.milestone-stretch-goal.incomplete').each(function () {
        const $this = $(this);
        let amount = $this.find('p.item-details--amount-desc').text();
        if (amount) {
          // Remove all but amount value.
          amount = amount.split(/ -/);
          amount = parseInt(amount[0].replace(/[^0-9.]/g, ''));
          // If milestone reached.
          if (currentDonationAmount >= amount) {
            // Change item class name.
            $this.removeClass('incomplete').addClass('complete');
          }
        }
      });
      // Get max goal amount.
      let maxGoalAmount = $parentContainer.find('> ul').find('> li.milestone-stretch-goal:last').find('p.item-details--amount-desc').text();
      if (maxGoalAmount) {
        // Remove all but amount value.
        maxGoalAmount = maxGoalAmount.split(/ -/);
        maxGoalAmount = parseInt(maxGoalAmount[0].replace(/[^0-9.]/g, ''));
      }
      // If current donation amount >= goal amount.
      if (currentDonationAmount >= goalAmount) {
        // Change progress bar class names.
        $progressBarBackground.removeClass('goal-incomplete').addClass('goal-reached');
        $progressBarStretchGoal.removeClass('goal-incomplete').addClass('goal-reached');
        $progressBarNormal.removeClass('goal-incomplete').addClass('goal-reached');
        // Change milestone list item class names.
        $milestoneListContainer.find('> li').removeClass('goal-incomplete').addClass('goal-complete');
        // Remove child UL element.
        $milestoneListContainer.find('> ul').remove();
        // If a stretch goal exists. (A stretch goal is a milestone with a value greater than goal amount)
        if (hasStretchGoal) {
          $progressBarStretchGoal.css(
            'width',
            `${(0.5 * ((currentDonationAmount - goalAmount) / (maxGoalAmount - goalAmount) * 99)) + 50}%`
          );
          // If current donation amount >= max stretch goal amount.
          if (currentDonationAmount >= maxGoalAmount) {
            // Change stretch goal list item class name.
            $parentContainer.find('> ul').find('> li.milestone-stretch-goal').removeClass('incomplete').addClass('complete');
          }
        }
      }
      // Reposition milestones.
      $milestoneListContainer.find('> li.milestone__item:not(:last-child)').each(function () {
        const $this = $(this);
        let amount = $this.find('p.item-details--amount-desc').text();
        if (amount) {
          let position = 0;
          // Remove all but amount value.
          amount = amount.split(/ -/);
          amount = parseInt(amount[0].replace(/[^0-9.]/g, ''));
          // If milestone amount > goal amount.
          if (amount > goalAmount) {
            // Stretch goal.
            if (currentDonationAmount >= goalAmount && maxGoalAmount) {
              // Place milestone between 50-99% depending on progress from goal to max stretch goal.
              position = parseInt((0.5 * ((amount - goalAmount) / (maxGoalAmount - goalAmount) * 99)) + 50);
            } else {
              // Goal isn't complete, put all stretch goals at 95%.
              position = 95;
            }
          } else {
            // Regular goal.
            if (currentDonationAmount >= goalAmount && hasStretchGoal) {
              // Place between 0-50% depending on progress to normal goal.
              position = parseInt(0.5 * (amount / goalAmount) * 99);
            } else if (currentDonationAmount < goalAmount && hasStretchGoal) {
              position = parseInt(amount / goalAmount * 86);
            } else {
              position = parseInt(amount / goalAmount * 99);
            }
          }
          $this.attr('class', $this.attr('class').replace(/milestone__\d+/, `milestone__${position}`));
        }
      });
    }
  }

  function updateRecentDonors (payload) {
    const d = new Date(1000 * +payload.date_collected);
    const datestring = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;

    let donor = Mustache.render(donorTemplate, {
      collected_timestamp: payload.date_collected,
      collected_at: datestring,
      name: payload.recognition
    });
    donor = $(donor).addClass('new');

    $('.fundraising__stats.donors').removeClass('empty');
    const donors = $('.fundraising__stats.donors ol');
    donors.prepend(donor);

    setTimeout(() => donors.find('li.new').removeClass('new'), 1500);
    donors.find(`li:gt(${numRecentDonors - 1})`).remove();
  }

  function updateTopTeams (payload) {
    if (payload.team) {
      const amountRaised = formatNumber(payload.team_total, 2);
      const newTeam = Mustache.render(teamTemplate, {
        entity_id: payload.team,
        mypage_url: `/teams/mypage/${payload.team}/${eventYear}`,
        name: payload.team_name,
        amount_raised: amountRaised
      });

      const newItem = $(newTeam).addClass('new');
      const list = $('.fundraising__stats.teams ol');
      $('.fundraising__stats.teams').removeClass('empty');

      updateList(list, 'team', payload.team, newItem, amountRaised);
      setTimeout(() => list.find('li').removeClass('new update'), 1500);
      applyPositionClasses(list, numTopTeams);
    }
  }

  function updateTopParticipants (payload) {
    if (payload.participant || payload.fundraiser) {
      let id, name, total, type;
      if (payload.participant) {
        id = payload.participant;
        name = payload.participant_name;
        total = +payload.participant_total;
        type = 'participant';
      } else {
        id = payload.fundraiser;
        name = payload.fundraiser_name;
        total = +payload.fundraiser_total;
        type = 'fundraiser';
      }

      const amountRaised = formatNumber(total, 2);
      const newParticipant = Mustache.render(participantTemplate, {
        entity_id: id,
        mypage_url: `/${type}s/mypage/${id}`,
        name,
        amount_raised: amountRaised,
        entity_type: type
      });

      const newItem = $(newParticipant).addClass('new');
      const list = $('.fundraising__stats.participants ol');
      $('.fundraising__stats.participants').removeClass('empty');

      updateList(list, type, id, newItem, amountRaised);
      setTimeout(() => list.find('li').removeClass('new update'), 1500);
      applyPositionClasses(list, numTopParticipants);
    }
  }

  function updateList (list, type, id, newItem, amountRaised) {
    list.each((_i, theList) => {
      const $theList = $(theList);
      const existingItem = $theList.find(`li[data-${type}-id="${id}"]`);
      if (existingItem.length) {
        existingItem.find('.totals__amount').text(amountRaised);
        existingItem.addClass('update');
      } else {
        $theList.append(newItem.clone());
      }
    });
  }

  function formatNumber (number, decimals = 0, includeCurrency = true) {
    const num = +number;
    return (includeCurrency ? '$' : '') + num.toFixed(decimals).replace(/./g, function (c, i, a) {
      return i && c !== '.' && ((a.length - i) % 3 === 0) ? `,${c}` : c;
    });
  }
}
